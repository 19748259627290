body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex: 1;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a > button {
  min-height: 40px;
}

#root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.faicon {
  width: 35px;
  height: 35px;
}

.pright {
  padding-right: 4px;
}

.pleft {
  padding-left: 4px;
}

/**
 * Custom Nerd UI styles
 */

/* TODO: move to isolated defualt theme */
.nerdUI .nerdUI--label {
  font-size: 0.875rem;
}

.cellReadOnly {
  background: rgba(155, 155, 155, 0.08);
  color: rgba(155, 155, 155);
  cursor: not-allowed;
}

.cell {
  border: solid 0.2px rgba(155, 155, 155, 0.2) !important;
}

.warning {
  background: rgba(255, 152, 0, 0.3);
  color: #ff6d00;
}

.header {
  background: rgba(155, 155, 155, 0.1);
}

.MuiDataGrid-toolbarContainer {
  background: rgba(155, 155, 155, 0.1);
}

.MuiDataGrid-columnHeaders {
  background: rgba(155, 155, 155, 0.1);
}

.MuiDataGrid-columnHeader {
  justify-content: center;
}

.MuiDataGrid-pinnedColumnHeaders {
  background: rgba(155, 155, 155, 0.1) !important;
  backdrop-filter: blur(200px);
}

.green {
  color: #4caf50;
}

.red {
  color: #f44336;
}

.rightAlign {
  text-align: right;
  padding-right: 8px !important;
}

.archived {
  opacity: 0.5;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.hidden {
  opacity: 0.5;
}

.exclude {
  background: rgba(255, 0, 0, 0.3) !important;
}

.lock {
  background: rgba(76, 175, 80, 0.1);
  cursor: not-allowed;
  pointer-events: none;
}
